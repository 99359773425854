"use client";

import { FC } from "react";
import { LoadingButtonProps } from "@mui/lab/LoadingButton";
import LoadingButton from "@mui/lab/LoadingButton";

export interface BazaarCtaProps
	extends LoadingButtonProps {
	submitButtonText?: string;
}

const BazaarCTA: FC<BazaarCtaProps> = ({
	submitButtonText = "Continuer",
	children,
	...props
}: BazaarCtaProps) => {
	return (
		<LoadingButton
			fullWidth
			size='large'
			variant='contained'
			color='dark'
			{...props}>
			{!children
				? submitButtonText
				: children}
		</LoadingButton>
	);
};

export default BazaarCTA;
