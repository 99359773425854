"use client";
import BazaarCTA from "components/BazaarCta";
import {
	FlexBox,
	FlexRowCenter,
} from "components/flex-box";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";

const NotFoundPageView = () => {
	const router = useRouter();
	const handleGoBack = () =>
		router.back();

	return (
		<FlexRowCenter
			my={6}
			px={2}
			flexDirection='column'>
			<Image
				alt='404 page'
				src={require("/public/assets/images/illustrations/404.svg")}
				style={{
					maxWidth: "350px",
					width: "100%",
					height: "auto",
				}}
			/>

			<FlexBox
				flexWrap='wrap'
				my={4}>
				<BazaarCTA
					color='secondary'
					sx={{ m: 1 }}
					onClick={handleGoBack}>
					Retourner
				</BazaarCTA>

				<BazaarCTA
					variant='contained'
					color='primary'
					sx={{ m: 1 }}
					LinkComponent={Link}
					href='/'>
					Accueil
				</BazaarCTA>
			</FlexBox>
		</FlexRowCenter>
	);
};

export default NotFoundPageView;
