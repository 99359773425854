import {
  Box,
  BoxProps,
} from "@mui/material";

export interface FlexRowCenterProps
  extends BoxProps {
  children: React.ReactNode;
}

const FlexRowCenter: React.FC<
  FlexRowCenterProps
> = ({ children, ...props }) => (
  <Box
    display="flex"
    justifyContent="flex-start"
    alignItems="center"
    {...props}
  >
    {children}
  </Box>
);

export default FlexRowCenter;
