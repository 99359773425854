import {
  Box,
  BoxProps,
} from "@mui/material";

export interface FlexBetweenProps
  extends BoxProps {
  children: React.ReactNode;
}

const FlexBetween: React.FC<
  FlexBetweenProps
> = ({ children, ...props }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  >
    {children}
  </Box>
);

export default FlexBetween;
